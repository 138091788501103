import {
  H1,
  InfoIcon,
  ReturnIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { InfoBox } from '@hp/components';
import { PiaStatus } from '@hp/core/shared';
import { CenteredRow } from '@hp/layout';
import { colors } from '@hp/theme';
import { gtmVirtualPageView } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC, useEffect } from 'react';

import { ExpiredProps } from '../types';

export const Expired: FC<ExpiredProps> = ({ id, isPaid, isRefunded }) => {
  useEffect(() => {
    gtmVirtualPageView(PiaStatus.EXPIRED);
  }, []);

  return (
    <>
      <CenteredRow marginSize="xl" noBottomMargin>
        <ReturnIcon />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="expired.returning.title">
            Zásilku jsme vrátili odesílateli
          </Trans>
        </H1>
      </CenteredRow>

      <InfoBox icon={<InfoIcon />}>
        <Typography type={TypographyType.BodySmallLight} color={colors.black}>
          <Trans id="expired.infoText.parcelNotPickedUp">
            Zásilka {id} bohužel nebyla nevyzvednuta, a proto ji posíláme zpět
            odesílateli.
          </Trans>
          <br />
          <br />

          {isPaid && (
            <>
              <Typography type={TypographyType.BodySmall}>
                <Trans id="expired.infoText.returnMoney">
                  Dobírkovou částku vám vrátíme na kartu.
                </Trans>
              </Typography>
              <br />
              <br />
            </>
          )}

          {isRefunded && (
            <>
              <Typography type={TypographyType.BodySmall}>
                <Trans id="expired.infoText.returnMoney.refunded">
                  Dobírkovou částku jsme vám vrátili na kartu.
                </Trans>
              </Typography>
              <br />
              <br />
            </>
          )}

          <Trans id="expired.infoText.contactSender">
            Pokud byste o zásilku stále měli zájem, domluvte se prosím s
            odesílatelem.
          </Trans>
        </Typography>
      </InfoBox>
    </>
  );
};

import {
  Button,
  ButtonType,
  ConfirmIcon,
  H1,
  InfoIcon,
  Typography,
  TypographyType,
  XCircleIcon,
} from '@hp/atomic';
import { InfoBox } from '@hp/components';
import { CenteredRow } from '@hp/layout';
import { colors } from '@hp/theme';
import { addDataLayerItem, gtmVirtualPageView } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC, useEffect } from 'react';

import { NotPaidProps } from '../types';

export const NotPaid: FC<NotPaidProps> = ({ pay }) => {
  useEffect(() => {
    gtmVirtualPageView('PAYMENT_ERROR');
  }, []);

  return (
    <>
      <CenteredRow marginSize="xl" noBottomMargin>
        <XCircleIcon />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="notPaid.title">Platba bohužel neproběhla</Trans>
        </H1>
      </CenteredRow>

      <InfoBox icon={<InfoIcon />}>
        <Typography type={TypographyType.BodySmallLight} color={colors.black}>
          <Trans id="notPaid.infoText">
            Zkuste prosím zkontrolovat, že jste zadali správné platební údaje,
            máte povolené platby na internetu a dostatek peněz na účtu.
          </Trans>
        </Typography>
      </InfoBox>

      <CenteredRow>
        <Button
          buttonType={ButtonType.PRIMARY}
          icon={<ConfirmIcon />}
          bottom
          onClick={() => {
            pay();
            addDataLayerItem({ event: 'cod_payment_requested_again' });
          }}
          className="gaButton gaButtonAction_tryPaymentAgain"
        >
          <Trans id="common.button.tryAgain">Zkusit znovu</Trans>
        </Button>
      </CenteredRow>
    </>
  );
};

import { breakpoints, colors, elements, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

import { BannerProps } from '../types';

export const Wrapper = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.white};
  height: ${({ size }) => (size === 'M' ? '320px' : '172px')};
  max-width: 288px;
  background-color: ${colors.red_main};
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 40%,
      rgba(255, 255, 255, 0) 120%
    ),
    url('/images/banners/promo_banner.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 13px 15px;
  margin: ${spacing.m} auto ${spacing.l};

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 504px;
    height: ${({ size }) => (size === 'M' ? '320px' : '280px')};
    max-width: ${elements.innerElementMaxWidth};
    padding: 20px 24px;
    margin: ${spacing.m} auto 0;
  }
`;

export const Heading = styled.h3<BannerProps>`
  color: ${colors.red_main};
  text-align: left;
  font-size: 29px;
  line-height: 29px;
  font-weight: ${typography.fontWeight.normal};
  max-width: 224px;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 30px;
    line-height: 30px;
    max-width: 224px;
  }
`;

export const Paragraph = styled.p<BannerProps>`
  color: ${colors.black};
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  font-weight: ${typography.fontWeight.light};
  max-width: 130px;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 18px;
    line-height: 28px;
    max-width: 224px;
  }
`;

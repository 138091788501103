import { LocationIcon } from '@hp/atomic';
import { Row as BaseRow } from '@hp/layout';
import { breakpoints } from '@hp/theme';
import styled from 'styled-components';

export const Row = styled(BaseRow)`
  margin-bottom: 0;
`;

export const Collapsible = styled.div<{ collapsed: boolean }>`
  overflow: hidden;
  transition: max-height 0.7s ease-out;
  height: auto;
  max-height: ${({ collapsed }) => (collapsed ? '600px' : '0px')};

  @media (min-width: ${breakpoints.minDesktop}) {
    max-height: ${({ collapsed }) => (collapsed ? '1200px' : '0px')};
  }
`;

export const LocationIconStyled = styled(LocationIcon)`
  top: 0;
  left: 0;
  width: 28px;
  height: 32px;
  position: absolute;
`;

import { colors, elements, spacing, typography } from '@hp/theme';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 3px rgba(49, 50, 48, 0.05);
  border-radius: 2px;
  background-color: ${colors.input};
  padding: 0 ${spacing.s};
  width: 100%;
  border: 1px solid ${colors.gray_light};
  height: ${elements.inputHeight};
`;

export const ReactCountryFlagStyled = styled(ReactCountryFlag)<{
  reversed?: boolean;
}>`
  width: '18px';
  height: '16px';
  margin-left: ${({ reversed }) => !reversed && spacing.s};
  margin-right: ${({ reversed }) => reversed && spacing.s};
`;

export const CenteredFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelStyled = styled.label`
  color: ${colors.gray_mid};
  font-weight: ${typography.fontWeight.normal};
  font-size: ${typography.fontSize.input.label};
  line-height: ${typography.lineHeight.body};
  height: 19px;
  margin-top: 2px;
`;

import { Button, ButtonType, ChevronNavBigIcon } from '@hp/atomic';
import { HideOnMobile } from '@hp/layout';
import { breakpoints, colors, spacing } from '@hp/theme';
import React, { Fragment, useRef } from 'react';
import styled from 'styled-components';

const Box = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE */
  overflow: -moz-scrollbars-none; /* Firefox */
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none; /* Chrome and Safari */
  }

  @media (min-width: ${breakpoints.minDesktop}) {
    height: 200px;
  }
`;

const Container = styled.div`
  height: 100%;
  display: inline-flex;

  @media (min-width: ${breakpoints.minDesktop}) {
    height: 200px;
  }
`;

const ButtonLeftStyled = styled(Button)`
  position: absolute;
  top: 80px;
  left: 16px;
  background-color: ${colors.white};
  padding: ${spacing.s};
  border-radius: 50px;
  opacity: 0.5;

  :hover {
    background-color: ${colors.white};
    opacity: 0.7;
  }
`;

const ButtonRightStyled = styled(ButtonLeftStyled)`
  left: auto;
  right: 16px;
`;

const ChevronNavBigIconInverse = styled(ChevronNavBigIcon)`
  transform: rotate(180deg);
`;

export const ScrollBox = ({ children }) => {
  const wrapperRef = useRef(null);

  const scroll = (direction: 'left' | 'right') => {
    const ref = wrapperRef?.current;

    if (ref) {
      ref.scrollLeft =
        direction === 'right' ? ref.scrollLeft + 100 : ref.scrollLeft - 100;
    }
  };

  return (
    <>
      <Box>
        <Wrapper ref={wrapperRef}>
          <Container role="list">
            {children.map((child, index) => (
              <Fragment key={index}>{child}</Fragment>
            ))}
          </Container>
        </Wrapper>

        {children.length > 1 && (
          <HideOnMobile>
            <ButtonLeftStyled
              type="button"
              buttonType={ButtonType.TEXT}
              onClick={() => scroll('left')}
            >
              <ChevronNavBigIcon />
            </ButtonLeftStyled>

            <ButtonRightStyled
              type="button"
              buttonType={ButtonType.TEXT}
              onClick={() => scroll('right')}
            >
              <ChevronNavBigIconInverse />
            </ButtonRightStyled>
          </HideOnMobile>
        )}
      </Box>
    </>
  );
};

import { H1, InfoIcon, Loading, Typography, TypographyType } from '@hp/atomic';
import { InfoBox } from '@hp/components';
import { CenteredRow } from '@hp/layout';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React from 'react';

export const PaymentChecking = () => {
  return (
    <>
      <CenteredRow marginSize="xl" noBottomMargin>
        <Loading height="100px" />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="paymentChecking.title"> Platba probíhá</Trans>
        </H1>
      </CenteredRow>

      <InfoBox icon={<InfoIcon />}>
        <Typography type={TypographyType.BodySmallLight} color={colors.black}>
          <Trans id="paymentChecking.infoText">
            Nezavírejte prosím okno. Nemělo by to trvat déle než 30 vteřin.
          </Trans>
        </Typography>
      </InfoBox>
    </>
  );
};

import { Typography, TypographyType } from '@hp/atomic';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { spacing } from '@hp/theme';
import { DayOfTheWeek, getDayName } from '@hp/utils';
import React from 'react';
import styled from 'styled-components';

import { OpeningHoursType } from './types';

type OpeningHoursProps = {
  data: OpeningHoursType;
};

export const OpeningHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${spacing.m};
`;

export const OpeningHoursRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: ${spacing.xs};
`;

export const OpeningHoursItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 92px;

  :last-child {
    text-align: right;
  }
`;

export const OpeningHours: React.FC<OpeningHoursProps> = ({ data }) => {
  const { language } = useLanguage();

  return (
    <OpeningHoursContainer>
      {Object.entries(data).map(([key, pairs]) => {
        const day = key as DayOfTheWeek;

        return (
          <OpeningHoursRow key={day}>
            <OpeningHoursItem>
              <Typography type={TypographyType.BodySmall}>
                {getDayName(day, language)}
              </Typography>
            </OpeningHoursItem>

            {pairs.map(({ open, close }, i) => (
              <OpeningHoursItem key={i}>
                <Typography type={TypographyType.BodySmallLight}>
                  {open} - {close}
                </Typography>
              </OpeningHoursItem>
            ))}
          </OpeningHoursRow>
        );
      })}
    </OpeningHoursContainer>
  );
};

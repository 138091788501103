import { Loading } from '@hp/atomic';
import { PiaStatus } from '@hp/core/shared';
import { MediaQueryJS, PageLayout } from '@hp/layout';
import React, { FC } from 'react';

import { NotFound } from '../Error/NotFound';
import { SomethingHappened } from '../Error/SomethingHappened';
import { DetailProps } from '../types';
import { PlaceDetail } from './PlaceDetail';
import { PlaceDetailDesktop } from './PlaceDetailDesktop';
import { useDetail } from './useDetail';

export const Detail: FC<DetailProps> = ({ id }) => {
  const { data, loading, error } = useDetail(id);

  if (loading)
    return (
      <PageLayout.default>
        <Loading />
      </PageLayout.default>
    );

  if (!loading && !data) return <NotFound />;

  if (error || data?.pia?.status === PiaStatus.ERROR)
    return (
      <PageLayout.default>
        <SomethingHappened />
      </PageLayout.default>
    );

  return (
    <MediaQueryJS>
      {(isMobileScreen) => (
        <>
          {isMobileScreen ? (
            <PageLayout.default>
              <PlaceDetail id={id} data={data} />
            </PageLayout.default>
          ) : (
            <PageLayout>
              <PlaceDetailDesktop id={id} data={data} />
            </PageLayout>
          )}
        </>
      )}
    </MediaQueryJS>
  );
};

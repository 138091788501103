/* eslint-disable */ 
import * as CoreTypes from '@hp/core/shared/graphql/generated/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type CreatePaymentMutationVariables = CoreTypes.Exact<{
  redirectUrl: CoreTypes.Scalars['String'];
  id: CoreTypes.Scalars['String'];
}>;


export type CreatePaymentMutation = { createPayment?: CoreTypes.Maybe<(
    Pick<CoreTypes.PayUCreateOrderResponse, 'extOrderId' | 'orderId' | 'redirectUri'>
    & { status: Pick<CoreTypes.Status, 'statusCode'> }
  )> };

export type PaymentQueryVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['String'];
}>;


export type PaymentQuery = { payment?: CoreTypes.Maybe<Pick<CoreTypes.PayURequest, 'orderId' | 'paymentId' | 'redirectUri' | 'status'>> };

export type PiaQueryVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['String'];
}>;


export type PiaQuery = { pia?: CoreTypes.Maybe<{ pia: (
      Pick<CoreTypes.PiaDetails, 'amount' | 'currency' | 'orderId' | 'status'>
      & { delivery: Pick<CoreTypes.Delivery, 'expirationDate' | 'pudoId' | 'type'>, payer?: CoreTypes.Maybe<Pick<CoreTypes.Recipient, 'email' | 'name' | 'phone'>>, sender: Pick<CoreTypes.Sender, 'name'> }
    ), place: (
      Pick<CoreTypes.PlaceDetails, 'dropOffAllowed' | 'enabled' | 'id' | 'lockerProvider' | 'note' | 'photos' | 'type'>
      & { contactInfo: Pick<CoreTypes.ContactInfo, 'email' | 'name' | 'note' | 'phone' | 'web'>, features: Pick<CoreTypes.Features, 'expressAllowed' | 'openInLateAfternoon' | 'openOnWeekends' | 'pickupAllowed' | 'returnAllowed'>, location: (
        Pick<CoreTypes.Location, 'note'>
        & { address: Pick<CoreTypes.Address, 'city' | 'country' | 'department' | 'floorSpecification' | 'note' | 'street' | 'zip'>, coordinates: Pick<CoreTypes.Coordinates, 'latitude' | 'longitude'> }
      ), openingHours: { monday?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Interval, 'open' | 'close'>>>>, tuesday?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Interval, 'open' | 'close'>>>>, wednesday?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Interval, 'open' | 'close'>>>>, thursday?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Interval, 'open' | 'close'>>>>, friday?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Interval, 'open' | 'close'>>>>, saturday?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Interval, 'open' | 'close'>>>>, sunday?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Interval, 'open' | 'close'>>>> }, paymentOptions: Pick<CoreTypes.PaymentOptions, 'cardPaymentAllowed' | 'codAllowed' | 'onlineCodAllowed' | 'prepaidAllowed'> }
    ) }> };

export type ResendSmsMutationVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['String'];
}>;


export type ResendSmsMutation = Pick<CoreTypes.Mutation, 'resendSMS'>;


export const CreatePaymentDocument = gql`
    mutation createPayment($redirectUrl: String!, $id: String!) {
  createPayment(redirectUrl: $redirectUrl, id: $id) {
    extOrderId
    orderId
    redirectUri
    status {
      statusCode
    }
  }
}
    `;
export type CreatePaymentMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>(CreatePaymentDocument, baseOptions);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = ApolloReactCommon.MutationResult<CoreTypes.CreatePaymentMutation>;
export type CreatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>;
export const PaymentDocument = gql`
    query payment($id: String!) {
  payment(id: $id) {
    orderId
    paymentId
    redirectUri
    status
  }
}
    `;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>(PaymentDocument, baseOptions);
      }
export function usePaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>(PaymentDocument, baseOptions);
        }
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentQueryResult = ApolloReactCommon.QueryResult<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>;
export const PiaDocument = gql`
    query pia($id: String!) {
  pia(id: $id) {
    pia {
      amount
      currency
      delivery {
        expirationDate
        pudoId
        type
      }
      orderId
      payer {
        email
        name
        phone
      }
      sender {
        name
      }
      status
    }
    place {
      contactInfo {
        email
        name
        note
        phone
        web
      }
      dropOffAllowed
      enabled
      features {
        expressAllowed
        openInLateAfternoon
        openOnWeekends
        pickupAllowed
        returnAllowed
      }
      id
      location {
        address {
          city
          country
          department
          floorSpecification
          note
          street
          zip
        }
        coordinates {
          latitude
          longitude
        }
        note
      }
      lockerProvider
      note
      openingHours {
        monday {
          open
          close
        }
        tuesday {
          open
          close
        }
        wednesday {
          open
          close
        }
        thursday {
          open
          close
        }
        friday {
          open
          close
        }
        saturday {
          open
          close
        }
        sunday {
          open
          close
        }
      }
      paymentOptions {
        cardPaymentAllowed
        codAllowed
        onlineCodAllowed
        prepaidAllowed
      }
      photos
      type
    }
  }
}
    `;

/**
 * __usePiaQuery__
 *
 * To run a query within a React component, call `usePiaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePiaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePiaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePiaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.PiaQuery, CoreTypes.PiaQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.PiaQuery, CoreTypes.PiaQueryVariables>(PiaDocument, baseOptions);
      }
export function usePiaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.PiaQuery, CoreTypes.PiaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.PiaQuery, CoreTypes.PiaQueryVariables>(PiaDocument, baseOptions);
        }
export type PiaQueryHookResult = ReturnType<typeof usePiaQuery>;
export type PiaLazyQueryHookResult = ReturnType<typeof usePiaLazyQuery>;
export type PiaQueryResult = ApolloReactCommon.QueryResult<CoreTypes.PiaQuery, CoreTypes.PiaQueryVariables>;
export const ResendSmsDocument = gql`
    mutation resendSMS($id: String!) {
  resendSMS(id: $id)
}
    `;
export type ResendSmsMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.ResendSmsMutation, CoreTypes.ResendSmsMutationVariables>;

/**
 * __useResendSmsMutation__
 *
 * To run a mutation, you first call `useResendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSmsMutation, { data, loading, error }] = useResendSmsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendSmsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.ResendSmsMutation, CoreTypes.ResendSmsMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.ResendSmsMutation, CoreTypes.ResendSmsMutationVariables>(ResendSmsDocument, baseOptions);
      }
export type ResendSmsMutationHookResult = ReturnType<typeof useResendSmsMutation>;
export type ResendSmsMutationResult = ApolloReactCommon.MutationResult<CoreTypes.ResendSmsMutation>;
export type ResendSmsMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.ResendSmsMutation, CoreTypes.ResendSmsMutationVariables>;
import { TypographyType } from '@hp/atomic';
import { colors } from '@hp/theme';
import React, { FC } from 'react';

import { BadgeText, BadgeWrapper } from './styled';
import { BadgeProps } from './types';

export const Badge: FC<BadgeProps> = ({
  bgColor = colors.gray_badge,
  textColor = colors.white,
  children,
}) => {
  return (
    <BadgeWrapper color={bgColor}>
      <BadgeText type={TypographyType.BodyMicro} color={textColor}>
        {children}
      </BadgeText>
    </BadgeWrapper>
  );
};

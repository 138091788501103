import {
  Button,
  ButtonType,
  CalendarClockIcon,
  ConfirmIcon,
  H1,
  ParcelColorsIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { AlertBox, OpeningBadge, PriceBox } from '@hp/components';
import { Language } from '@hp/config';
import { PiaStatus } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { CenteredRow, HideOnDesktop, Row, SpaceBetweenRow } from '@hp/layout';
import { gtmVirtualPageView } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC, useEffect } from 'react';

import { ParcelColorsSmallIconStyled } from '../styled';
import { ToBePaidProps } from '../types';
import { getLatestPickupLocalized } from './helper';

export const ToBePaid: FC<ToBePaidProps> = ({ data, pay }) => {
  const { language } = useLanguage();

  useEffect(() => {
    gtmVirtualPageView(PiaStatus.TO_BE_PAID);
  }, []);

  const currency =
    language === Language.CS && data?.pia?.currency === 'CZK'
      ? 'Kč'
      : data?.pia?.currency;

  return (
    <>
      <CenteredRow marginSize="xl" noBottomMargin>
        <ParcelColorsIcon />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="pay.parcelIsReady.title">Vaše zásilka je připravena</Trans>
        </H1>
      </CenteredRow>

      <PriceBox currency={currency}>{data?.pia?.amount}</PriceBox>

      <Row marginSize="s">
        <Typography type={TypographyType.BodyLight}>
          <Trans id="pay.parcelInfo">Informace o zásilce</Trans>
        </Typography>
      </Row>

      <Row>
        <Typography type={TypographyType.BodyMicroLight}>
          <Trans id="pay.parcelNumber">Číslo zásilky</Trans>:&nbsp;
        </Typography>

        <Typography type={TypographyType.BodyMicro}>
          {data?.pia?.orderId}
        </Typography>
      </Row>

      <Row>
        <Typography type={TypographyType.BodyMicroLight}>
          <Trans id="pay.Sender">Odesílatel</Trans>:&nbsp;
        </Typography>

        <Typography type={TypographyType.BodyMicro}>
          {data?.pia?.sender?.name}
        </Typography>
      </Row>

      {data?.pia?.delivery?.expirationDate && (
        <AlertBox icon={<CalendarClockIcon />}>
          <Trans id="pay.pickupAlert">
            Zásilku prosím zaplaťte a vyzvedněte nejpozději
          </Trans>{' '}
          {getLatestPickupLocalized(
            data?.pia?.delivery?.expirationDate,
            language,
          )}
        </AlertBox>
      )}

      <Row marginSize="s">
        <Typography type={TypographyType.BodyLight}>
          <Trans id="pay.pickupPlace">Místo vyzvednutí</Trans>
        </Typography>
      </Row>

      <Row marginSize="s" noBottomMargin>
        <ParcelColorsSmallIconStyled />

        <Typography type={TypographyType.BodyRegular}>
          {data?.place?.contactInfo?.name}
        </Typography>
      </Row>

      <SpaceBetweenRow marginSize="s">
        <Typography type={TypographyType.BodyMicroLight}>
          {data?.place?.location?.address?.street}
          <br />
          {data?.place?.location?.address?.city}
        </Typography>

        {data?.place && <OpeningBadge item={data?.place} />}
      </SpaceBetweenRow>

      <HideOnDesktop>
        <Row marginSize="xl" />
      </HideOnDesktop>

      <CenteredRow marginTop="l" noBottomMargin>
        <Button
          buttonType={ButtonType.PRIMARY}
          icon={<ConfirmIcon />}
          bottom
          onClick={() => pay()}
          className="gaButton gaButtonAction_pay"
        >
          <Trans id="common.button.payDelivery">Zaplatit dobírku</Trans>
        </Button>
      </CenteredRow>
    </>
  );
};

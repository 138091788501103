export enum ResendSmsStatus {
  READY = 'ready',
  SENT = 'sent',
  DISABLED = 'disabled',
}

export enum ResendSmsInfo {
  OK = 'ok',
  ERROR = 'error',
}

export type HomepageProps = {
  id: string;
  paymentError?: string;
  paymentRedirected?: string;
};

export type DetailProps = {
  id: string;
};

export type PlaceDetailProps = DetailProps & {
  data: any; //TODO: better type
};

export type ToBePaidProps = {
  data: any; //TODO: better type
  pay: () => void;
};

export type ReadyToPickupProps = {
  id: string;
  data: any; //TODO: better type
  resendSMS: () => void;
  resendStatus: ResendSmsStatus;
  resendInfo: ResendSmsInfo;
};

export type ExpiredProps = {
  id: string;
  isPaid: boolean;
  isRefunded: boolean;
};

export type NotPaidProps = {
  pay: () => void;
};

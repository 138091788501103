import {
  Button,
  ButtonType,
  CalendarClockIcon,
  CheckCircleIcon,
  H1,
  InfoIcon,
  MobileChatIcon,
  Nowrap,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { AlertBox, InfoBox, PlaceBox, PromoBanner } from '@hp/components';
import { config } from '@hp/config';
import { PiaStatus } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { CenteredRow } from '@hp/layout';
import { colors } from '@hp/theme';
import { gtmVirtualPageView } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';

import { CheckCircleIconStyled } from '../styled';
import { ReadyToPickupProps, ResendSmsInfo, ResendSmsStatus } from '../types';
import { getLatestPickupLocalized } from './helper';

export const ReadyToPickup: FC<ReadyToPickupProps> = ({
  id,
  data,
  resendSMS,
  resendStatus,
  resendInfo,
}) => {
  const { language } = useLanguage();

  useEffect(() => {
    gtmVirtualPageView(PiaStatus.READY_TO_PICKUP);
  }, []);

  const {
    customerSupport: { phone },
  } = config;

  const COUNTER_SEC = 30;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleClick = () => {
    resendSMS();
    if (counter === 0) setCounter(COUNTER_SEC);
  };

  return (
    <>
      <CenteredRow marginSize="xl" noBottomMargin>
        <CheckCircleIcon />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="paid.parcelIsPaid.title">
            Máte zaplaceno. <br /> Zásilku si můžete vyzvednout
          </Trans>
        </H1>
      </CenteredRow>

      {data?.pia?.delivery?.expirationDate && (
        <AlertBox icon={<CalendarClockIcon />}>
          <Trans id="paid.pickupAlert">
            Zásilku si prosím vyzvedněte nejpozději
          </Trans>{' '}
          {getLatestPickupLocalized(
            data?.pia?.delivery?.expirationDate,
            language,
          )}
        </AlertBox>
      )}

      <InfoBox icon={<MobileChatIcon />}>
        <Typography type={TypographyType.BodySmallLight} color={colors.black}>
          <Trans id="paid.sms.infoText">
            Šestimístný kód pro otevření schránky boxu jsme vám poslali smskou a
            e-mailem.
          </Trans>
        </Typography>
      </InfoBox>

      <CenteredRow>
        <Button
          buttonType={
            resendStatus === ResendSmsStatus.READY
              ? ButtonType.SECONDARY
              : ButtonType.SECONDARY_GREEN
          }
          onClick={() => handleClick()}
          className="gaButton gaButtonAction_resendSMS"
          disabled={resendStatus !== ResendSmsStatus.READY}
        >
          {resendStatus === ResendSmsStatus.SENT ? (
            <>
              <CheckCircleIconStyled />
              <Trans id="paid.resendSMS.sent.button">Kód zaslán</Trans>
            </>
          ) : resendStatus === ResendSmsStatus.DISABLED ? (
            <>
              <CheckCircleIconStyled />
              <Trans id="paid.resendSMS.button.timeout">
                Zaslat kód znovu za
              </Trans>
              {counter < 10 ? ' 00:0' + counter : ' 00:' + counter}
            </>
          ) : (
            <>
              <Trans id="paid.resendSMS.button">Zaslat kód znovu</Trans>
            </>
          )}
        </Button>
      </CenteredRow>

      {resendInfo === ResendSmsInfo.OK && (
        <InfoBox icon={<InfoIcon color={colors.green} />} boxType="success">
          <Typography type={TypographyType.BodyMicro} color={colors.green}>
            <Trans id="paid.sms.infoText.ok">
              Kód Vám byl zaslán na telefonní číslo
              {` ${data?.pia?.payer?.phone}`}
              <Nowrap> a e-mailovou</Nowrap> adresu
              {` ${data?.pia?.payer?.email}`}. Pokud vám SMS s kódem ani e-mail
              nepřišli, zkuste prosím zaslat znovu nebo se obraťte na naši
              zákaznickou linku: <Nowrap>{phone}.</Nowrap>
            </Trans>
          </Typography>
        </InfoBox>
      )}

      {resendInfo === ResendSmsInfo.ERROR && (
        <InfoBox icon={<InfoIcon color={colors.red_main} />} boxType="error">
          <Typography type={TypographyType.BodyMicro} color={colors.red_main}>
            <Trans id="paid.sms.infoText.error">
              SMS se nepodařilo odeslat. Zkontrolujte prosím vaši
              <Nowrap> e-mailovou</Nowrap>
              schránku, zda Vám PIN přišel alespoň mailem. V opačném případě se
              prosím obraťte na naši zákaznickou linku:{' '}
              <Nowrap>{phone}.</Nowrap>
            </Trans>
          </Typography>
        </InfoBox>
      )}

      <PlaceBox
        id={id}
        name={data?.place?.contactInfo?.name}
        street={data?.place?.location?.address?.street}
        city={data?.place?.location?.address?.city}
        place={data?.place}
      />

      <PromoBanner size="S" />
    </>
  );
};

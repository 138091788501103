import { Typography, TypographyType } from '@hp/atomic';
import { colors, spacing } from '@hp/theme';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

type AlertBoxProps = {
  icon: ReactNode;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${colors.red_opacity_005};
  padding: 12px 10px;
  border-radius: 5px;
  margin: ${spacing.m} 0;
`;

const IconWrapper = styled.div`
  margin-right: ${spacing.s};
`;

export const AlertBox: FC<AlertBoxProps> = ({ icon, children }) => {
  return (
    <Container>
      <IconWrapper>{icon}</IconWrapper>

      <Typography type={TypographyType.BodyMicro} color={colors.red_main}>
        {children}
      </Typography>
    </Container>
  );
};

import {
  Button,
  ButtonType,
  ExclamationTriangleIcon,
  H1,
  InfoIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { InfoBox } from '@hp/components';
import { CenteredRow, HideOnDesktop, PageLayout, Row } from '@hp/layout';
import { colors } from '@hp/theme';
import { gtmVirtualPageView } from '@hp/utils';
import { Trans } from '@lingui/macro';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export const InternalServerError = () => {
  const router = useRouter();

  useEffect(() => {
    gtmVirtualPageView('500');
  }, []);

  return (
    <PageLayout.default>
      <CenteredRow marginSize="l" noBottomMargin>
        <ExclamationTriangleIcon />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="error.500.title">
            Omlouváme se, stránku se teď nedaří načíst
          </Trans>
        </H1>
      </CenteredRow>

      <InfoBox icon={<InfoIcon />}>
        <Typography type={TypographyType.BodySmallLight} color={colors.black}>
          <Trans id="error.500.description">
            Zkuste to prosím znovu později.
          </Trans>
        </Typography>
      </InfoBox>

      <HideOnDesktop>
        <Row marginSize="xl" />
      </HideOnDesktop>

      <CenteredRow marginSize="l" noBottomMargin>
        <Button
          type="button"
          buttonType={ButtonType.PRIMARY}
          bottom
          onClick={() => {
            router.reload();
          }}
        >
          <Trans id="common.button.tryAgain">Zkusit znovu</Trans>
        </Button>
      </CenteredRow>
    </PageLayout.default>
  );
};

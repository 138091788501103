import { usePiaQuery } from '../../graphql';

export const useDetail = (id: string) => {
  const { data, loading, error } = usePiaQuery({
    variables: { id },
  });

  return {
    data: data?.pia || null,
    loading,
    error,
  };
};

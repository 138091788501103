import {
  Button,
  ButtonType,
  ParcelColorsSmallIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { Row, SpaceBetweenRow } from '@hp/layout';
import { colors, spacing } from '@hp/theme';
import { addDataLayerItem } from '@hp/utils';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';
import styled from 'styled-components';

import { useRouter } from '../../seo';
import { OpeningBadge } from './OpeningBadge';

type PlaceBoxProps = {
  id: string;
  name: string;
  street: string;
  city: string;
  place?: any;
};

const ParcelColorsSmallIconStyled = styled(ParcelColorsSmallIcon)`
  flex-shrink: 0;
  margin-right: ${spacing.s};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.gray};
  padding: 12px 10px;
  border-radius: 5px;
  margin: ${spacing.m} 0;
`;

const ButtonStyled = styled(Button)`
  min-width: 40px;
  margin: ${spacing.xs} 0 auto auto;
`;

export const PlaceBox: FC<PlaceBoxProps> = ({
  id,
  name,
  street,
  city,
  place,
}) => {
  const router = useRouter();

  return (
    <Container>
      <Row marginSize="zero" nowrap>
        <ParcelColorsSmallIconStyled />

        <Typography type={TypographyType.BodyRegular}>{name}</Typography>

        <ButtonStyled
          type="button"
          buttonType={ButtonType.TEXT}
          onClick={() => {
            addDataLayerItem({ event: 'place_detail' });
            router.push('detail', {
              id: id,
            });
          }}
        >
          <Trans id="common.button.more">Více</Trans>
        </ButtonStyled>
      </Row>

      <SpaceBetweenRow marginSize="s" noBottomMargin>
        <Typography type={TypographyType.BodyMicroLight}>
          {street}
          <br />
          {city}
        </Typography>

        {place && <OpeningBadge item={place} />}
      </SpaceBetweenRow>
    </Container>
  );
};

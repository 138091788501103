import { Button, CheckCircleIcon, ParcelColorsSmallIcon } from '@hp/atomic';
import { breakpoints, colors, spacing } from '@hp/theme';
import styled from 'styled-components';

export const ParcelColorsSmallIconStyled = styled(ParcelColorsSmallIcon)`
  margin-right: ${spacing.s};
`;

export const CheckCircleIconStyled = styled(CheckCircleIcon)`
  height: 32px;
  width: 32px;
  margin-right: ${spacing.m};
`;

export const SubNavSpacer = styled.div`
  width: 24px;
`;

export const Photo = styled.img`
  height: 120px;
  width: auto;
  margin-right: ${spacing.m};

  @media (min-width: ${breakpoints.minDesktop}) {
    height: 200px;
  }
`;

export const DayName = styled.span`
  min-width: 120px;
`;

export const DetailDesktopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const PlaceInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  background-color: ${colors.white};
  padding: 0 ${spacing.m};
`;

export const MapColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BackButton = styled(Button)`
  margin-right: ${spacing.m};
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing.m};
`;

export const DescriptionWrapper = styled.div`
  margin-left: 36px;
`;

import { Language } from '@hp/core/shared';
import { format, getDay, parseISO } from 'date-fns';

export const getLatestPickupLocalized = (date: string, language: Language) => {
  const expDate = format(parseISO(date), 'd.M.');
  const expHour = format(parseISO(date), 'kk:mm');
  const dayNumber = getDay(parseISO(date));

  switch (dayNumber) {
    case 0:
      return language === Language.CS
        ? `v neděli ${expDate} do ${expHour} hodin.`
        : `Sunday, ${expDate} by ${expHour}.`;
    case 1:
      return language === Language.CS
        ? `v pondělí ${expDate} do ${expHour} hodin.`
        : `Monday, ${expDate} by ${expHour}.`;
    case 2:
      return language === Language.CS
        ? `v úterý ${expDate} do ${expHour} hodin.`
        : `Tuesday, ${expDate} by ${expHour}.`;
    case 3:
      return language === Language.CS
        ? `ve středu ${expDate} do ${expHour} hodin.`
        : `Wednesday, ${expDate} by ${expHour}.`;
    case 4:
      return language === Language.CS
        ? `ve čtvrtek ${expDate} do ${expHour} hodin.`
        : `Thursday, ${expDate} by ${expHour}.`;
    case 5:
      return language === Language.CS
        ? `v pátek ${expDate} do ${expHour} hodin.`
        : `Friday, ${expDate} by ${expHour}.`;
    case 6:
      return language === Language.CS
        ? `v sobotu ${expDate} do ${expHour} hodin.`
        : `Saturday, ${expDate} by ${expHour}.`;
    default:
      return '';
  }
};

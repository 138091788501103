import { PayUStatus, PiaStatus } from '@hp/core/shared/graphql/generated/types';
import { useSystemModal } from '@hp/core/src/providers/SystemModal';
import { useRouter } from '@hp/seo';
import { addDataLayerItem } from '@hp/utils';
import useInterval from '@rooks/use-interval';
import { useEffect, useState } from 'react';

import {
  useCreatePaymentMutation,
  usePaymentQuery,
  usePiaQuery,
  useResendSmsMutation,
} from '../../graphql';
import { ResendSmsInfo, ResendSmsStatus } from '../types';

export const useHomepage = (id: string, paymentRedirected: string) => {
  const { getGlobalUrl } = useRouter();
  const { showErrorInfo } = useSystemModal();
  const [createPayment] = useCreatePaymentMutation();
  const [resendSms] = useResendSmsMutation();

  const [paymentChecking, setPaymentChecking] = useState(!!paymentRedirected);
  const [checkingDelay, setCheckingDelay] = useState<number>(1000);
  const [resendStatus, setResendStatus] = useState<ResendSmsStatus>(
    ResendSmsStatus.READY,
  );
  const [resendInfo, setResendInfo] = useState<ResendSmsInfo>(null);

  const { data, loading, error, refetch } = usePiaQuery({
    variables: { id },
  });

  const { data: dataPayment, loading: loadingPayment } = usePaymentQuery({
    variables: { id },
  });

  const checkResult = () => {
    if (error) return;

    if (paymentChecking) {
      refetch();
      const nextAttempt = Math.round(Math.min(checkingDelay * 1.1, 10000));
      setCheckingDelay(nextAttempt);

      if (
        data?.pia?.pia?.status === PiaStatus.READY_TO_PICKUP ||
        (!loadingPayment && dataPayment?.payment === null)
      ) {
        stop();
        setPaymentChecking(false);
        addDataLayerItem({ event: 'cod_payment_completed' });
        //TODO: use router push but without refresh, for now replacing url with this hack
        // nextRouter.replace('landing', id);
        history.pushState({}, null, `${window.location.origin}/${id}`);
      }

      if (data?.pia?.pia?.status === PiaStatus.ERROR) {
        stop();
        setPaymentChecking(false);
      }
    }
  };

  const [start, stop] = useInterval(checkResult, checkingDelay);

  useEffect(() => {
    if (paymentChecking) start();
  }, []);

  useEffect(() => {
    if (
      !paymentChecking &&
      data?.pia?.pia?.status === PiaStatus.PAID &&
      dataPayment?.payment?.status !== PayUStatus.REFUNDED
    ) {
      setPaymentChecking(true);
      start();
    }
  }, [data, dataPayment]);

  const pay = async () => {
    addDataLayerItem({ event: 'cod_payment_requested' });

    try {
      const { data } = await createPayment({
        variables: {
          id,
          redirectUrl: `${getGlobalUrl('landing')}${id}?payment=true`,
        },
      });

      document.location.href = data.createPayment.redirectUri;
    } catch (e) {
      await showErrorInfo();
    }
  };

  const resendSMS = async () => {
    if (resendStatus === ResendSmsStatus.READY) {
      try {
        const { data } = await resendSms({
          variables: {
            id,
          },
        });

        if (!data) {
          setResendInfo(ResendSmsInfo.ERROR);
          await showErrorInfo();
        } else {
          setResendStatus(ResendSmsStatus.SENT);
          setResendInfo(ResendSmsInfo.OK);
          addDataLayerItem({ event: 'pin_resend_requested' });
        }
      } catch (e) {
        setResendInfo(ResendSmsInfo.ERROR);
        await showErrorInfo();
      }
    }
  };

  useEffect(() => {
    if (resendStatus === ResendSmsStatus.SENT)
      setTimeout(() => setResendStatus(ResendSmsStatus.DISABLED), 4000);

    if (resendStatus === ResendSmsStatus.DISABLED)
      setTimeout(() => setResendStatus(ResendSmsStatus.READY), 26000);
  }, [resendStatus]);

  return {
    data: data?.pia || null,
    loading,
    error,
    dataPayment: dataPayment?.payment || null,
    loadingPayment,
    pay,
    resendSMS,
    resendStatus,
    setResendStatus,
    resendInfo,
    paymentChecking,
  };
};

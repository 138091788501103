import { colors } from '@hp/theme';
import { DayOfTheWeek, useServerTime } from '@hp/utils';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { Badge } from './badge';
import { OpenClosePair, OpeningHoursType } from './types';

const getHoursAndMinuts = (timeStr: string) => {
  return timeStr.split(':').map((x) => Number.parseInt(x));
};

const isInRange = ({ close, open }: OpenClosePair, currentWeight: number) => {
  const [a, b] = getHoursAndMinuts(open);
  const openWeight = a * 100 + b;

  if (currentWeight < openWeight) return false;

  const [c, d] = getHoursAndMinuts(close);
  const closeWeight = c * 100 + d;

  return currentWeight < closeWeight;
};

const checkIsOpen = (serverTime: Date, openingHours: OpeningHoursType) => {
  const d = serverTime;
  const dayOfWeekIndex = (d.getDay() + 6) % 7;
  const dayOfWeek = Object.values(DayOfTheWeek)[dayOfWeekIndex];
  const hoursPerDay = openingHours[dayOfWeek];

  if (!hoursPerDay) return false;
  const currentWeight = d.getHours() * 100 + d.getMinutes();

  return (
    hoursPerDay && hoursPerDay.some((pair) => isInRange(pair, currentWeight))
  );
};

export const OpeningBadge: FC<{
  item: any;
}> = ({ item: { openingHours, enabled } }) => {
  const { correctedObj } = useServerTime();

  if (!enabled)
    return (
      <Badge bgColor="transparent" textColor={colors.gray_badge}>
        <Trans id="common.shopState.disabled">Nedostupné</Trans>
      </Badge>
    );

  if (!correctedObj || !openingHours) return null;

  const isOpen = checkIsOpen(correctedObj, openingHours);

  return isOpen ? (
    <Badge bgColor={colors.green}>
      <Trans id="common.shopState.open">Otevřeno</Trans>
    </Badge>
  ) : (
    <Badge bgColor={colors.gray_badge}>
      <Trans id="common.shopState.closed">Zavřeno</Trans>
    </Badge>
  );
};

import { DeliveredIcon, H1 } from '@hp/atomic';
import { PromoBanner } from '@hp/components';
import { PiaStatus } from '@hp/core/shared';
import { CenteredRow, Row } from '@hp/layout';
import { gtmVirtualPageView } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';

export const Delivered = () => {
  useEffect(() => {
    gtmVirtualPageView(PiaStatus.DELIVERED);
  }, []);

  return (
    <>
      <CenteredRow marginSize="xl" noBottomMargin>
        <DeliveredIcon />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="delivered.parcelIsPaid.title">
            Zásilka už byla vyzvednuta
          </Trans>
        </H1>
      </CenteredRow>

      <Row marginSize="l" />

      <PromoBanner size="S" />
    </>
  );
};

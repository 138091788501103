import { Loading } from '@hp/atomic';
import { PayUStatus, PiaStatus } from '@hp/core/shared';
import { PageLayout } from '@hp/layout';
import { addDataLayerItem } from '@hp/utils';
import { isPast, parseISO } from 'date-fns';
import React, { FC, useState } from 'react';

import { NotFound } from '../Error/NotFound';
import { SomethingHappened } from '../Error/SomethingHappened';
import { HomepageProps } from '../types';
import { Delivered } from './Delivered';
import { Expired } from './Expired';
import { NotPaid } from './NotPaid';
import { PaymentChecking } from './PaymentChecking';
import { ReadyToPickup } from './ReadyToPickup';
import { ToBePaid } from './ToBePaid';
import { useHomepage } from './useHomepage';

export const Homepage: FC<HomepageProps> = ({
  id,
  paymentError,
  paymentRedirected,
}) => {
  const {
    data,
    loading,
    error,
    dataPayment,
    loadingPayment,
    pay,
    resendSMS,
    resendStatus,
    resendInfo,
    paymentChecking,
  } = useHomepage(id, paymentRedirected);

  const [paymentCancelledEventSent, setPaymentCancelledEventSent] = useState<
    boolean
  >(false);

  if (paymentChecking && !paymentError)
    return (
      <PageLayout.default>
        <PaymentChecking />
      </PageLayout.default>
    );

  if (loading || loadingPayment)
    return (
      <PageLayout.default>
        <Loading />
      </PageLayout.default>
    );

  if (!loading && !data) return <NotFound />;

  if (error || data?.pia?.status === PiaStatus.ERROR)
    return (
      <PageLayout.default>
        <SomethingHappened />
      </PageLayout.default>
    );

  if (
    paymentError === '501' &&
    !loadingPayment &&
    (dataPayment?.status === PayUStatus.CANCELED ||
      dataPayment?.status === PayUStatus.PENDING)
  ) {
    if (!paymentCancelledEventSent) {
      setPaymentCancelledEventSent(true);
      addDataLayerItem({ event: 'cod_payment_canceled' });
    }

    return (
      <PageLayout.default>
        <NotPaid pay={pay} />
      </PageLayout.default>
    );
  }

  if (data?.pia?.status === PiaStatus.TO_BE_PAID) {
    return (
      <PageLayout.default>
        <ToBePaid data={data} pay={pay} />
      </PageLayout.default>
    );
  }

  if (data?.pia?.status === PiaStatus.READY_TO_PICKUP) {
    return (
      <PageLayout.default>
        <ReadyToPickup
          id={id}
          data={data}
          resendSMS={resendSMS}
          resendStatus={resendStatus}
          resendInfo={resendInfo}
        />
      </PageLayout.default>
    );
  }

  if (data?.pia?.status === PiaStatus.DELIVERED) {
    return (
      <PageLayout.default>
        <Delivered />
      </PageLayout.default>
    );
  }

  if (
    data?.pia?.status === PiaStatus.EXPIRED ||
    isPast(parseISO(data?.pia?.delivery?.expirationDate))
  ) {
    return (
      <PageLayout.default>
        <Expired
          id={data?.pia?.orderId}
          isPaid={dataPayment?.status === PayUStatus.COMPLETED}
          isRefunded={dataPayment?.status === PayUStatus.REFUNDED}
        />
      </PageLayout.default>
    );
  }

  return null;
};

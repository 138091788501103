import { Button, ButtonType, ChevronIcon, Nowrap } from '@hp/atomic';
import { colors } from '@hp/theme';
import { addDataLayerItem } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { BannerProps } from '../types';
import { Paragraph, Wrapper } from './styled';

export const PromoBanner: FC<BannerProps> = ({ size = 'M' }) => {
  return (
    <Wrapper size={size}>
      <Paragraph size={size}>
        <Trans id="banner.promo.paragraph">
          Pokud byste balíček potřebovali vrátit odesílateli, všechno zadáte
          online <Nowrap>a nemusíte nic tisknout.</Nowrap>
        </Trans>
      </Paragraph>

      <Button
        type="button"
        buttonType={ButtonType.BANNER}
        icon={<ChevronIcon color={colors.red_main} zoom={1} />}
        className="gaButton gaButtonAction_promoBanner"
        //TODO: move url to config (after config cleanup)
        onClick={() => {
          addDataLayerItem({ event: 'banner_c2c' });
          window.open('https://zrukydoruky.dpd.cz/', '_blank');
        }}
      >
        <Trans id="banner.promo.button">Více o přepravě</Trans>
      </Button>
    </Wrapper>
  );
};

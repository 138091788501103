import {
  Button,
  ButtonType,
  ExclamationTriangleIcon,
  H1,
  InfoIcon,
  Nowrap,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { InfoBox } from '@hp/components';
import { config, Language } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { CenteredRow, HideOnDesktop, Row } from '@hp/layout';
import { colors } from '@hp/theme';
import { gtmVirtualPageView } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';

export const SomethingHappened = () => {
  const { language } = useLanguage();

  useEffect(() => {
    gtmVirtualPageView('ERROR');
  }, []);

  const {
    app: { onlineCodDpd, onlineCodDpdEn },
    customerSupport: { phone, phonePrefix },
  } = config;

  const href = language === Language.CS ? onlineCodDpd : onlineCodDpdEn;

  return (
    <>
      <CenteredRow marginSize="xl" noBottomMargin>
        <ExclamationTriangleIcon />
      </CenteredRow>

      <CenteredRow>
        <H1 fontWeight="light" textAlign="center">
          <Trans id="error.somethingHappened.title">Došlo k chybě</Trans>
        </H1>
      </CenteredRow>

      <InfoBox icon={<InfoIcon />}>
        <Typography type={TypographyType.BodySmallLight} color={colors.black}>
          <Trans id="error.somethingHappened.infoText">
            Omlouváme se, ale kvůli technickým potížím teď nemůžeme váš
            požadavek zpracovat. Zkuste to prosím později nebo nám zavolejte na
            číslo
          </Trans>{' '}
          <Nowrap>
            <a href={`tel: ${phonePrefix} ${phone}`}>
              {language !== Language.CS && phonePrefix}
              {phone}
            </a>
            .
          </Nowrap>
        </Typography>
      </InfoBox>

      <HideOnDesktop>
        <Row marginSize="xl" />
      </HideOnDesktop>

      <CenteredRow marginSize="l" noBottomMargin>
        <a href={href}>
          <Button type="button" buttonType={ButtonType.PRIMARY} bottom>
            <Trans id="common.homepage">Hlavní stránka</Trans>
          </Button>
        </a>
      </CenteredRow>
    </>
  );
};

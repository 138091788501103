import {
  Button,
  ButtonType,
  ChevronNavBigIcon,
  GoogleMap,
  Hr,
  Nowrap,
  PaymentCardIcon,
  PaymentCashIcon,
  PaymentMobileIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { OpeningHours, ScrollBox } from '@hp/components';
import { Language } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { CenteredRow, HideOnDesktop, Row, SpaceBetweenRow } from '@hp/layout';
import { useRouter } from '@hp/seo';
import { addDataLayerItem } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import {
  DescriptionWrapper,
  IconWrapper,
  Photo,
  SubNavSpacer,
} from '../styled';
import { PlaceDetailProps } from '../types';

export const PlaceDetail: FC<PlaceDetailProps> = ({ id, data }) => {
  const { language } = useLanguage();

  const router = useRouter();

  const phone =
    language === Language.CS
      ? data?.place?.contactInfo?.phone
      : `+420 ${data?.place?.contactInfo?.phone}`;

  return (
    <>
      <SpaceBetweenRow marginSize="l">
        <Button
          type="button"
          buttonType={ButtonType.TEXT}
          onClick={() => {
            addDataLayerItem({ event: 'place_detail_back' });
            router.push('landing', {
              id: id,
            });
          }}
        >
          <ChevronNavBigIcon />
        </Button>

        <Typography type={TypographyType.BodyLight}>
          <Trans id="detail.title">Detail místa odběru</Trans>
        </Typography>

        <SubNavSpacer />
      </SpaceBetweenRow>

      <Row marginSize="s">
        <Typography type={TypographyType.BodyExtraLargeLight}>
          {data?.place?.contactInfo?.name}
        </Typography>
      </Row>

      <Row marginSize="s">
        <Typography type={TypographyType.BodySmallLight}>
          {data?.place?.location?.address?.street}
          <br />
          {data?.place?.location?.address?.zip}{' '}
          {data?.place?.location?.address?.city}
          <br />
          {data?.place?.location?.note}
          <Typography type={TypographyType.BodySmall}>
            <Nowrap>
              <Trans id="detail.phone">Tel</Trans>
              {': '}
              <a href={`tel: ${phone}`}>{phone}</a>
            </Nowrap>
          </Typography>
        </Typography>
      </Row>

      <Hr />

      {data?.place?.photos?.length > 0 && (
        <>
          <Row marginSize="s">
            <ScrollBox>
              {data?.place?.photos?.map((photo) => {
                return <Photo key={photo} src={photo} />;
              })}
            </ScrollBox>
          </Row>

          <Hr noMargin />
        </>
      )}

      <Row marginSize="s" noBottomMargin>
        <Typography type={TypographyType.BodyLight}>
          <Trans id="detail.paymentOptions">Možnosti platby</Trans>
        </Typography>
      </Row>

      <Row marginSize="s">
        <Typography type={TypographyType.BodySmall}>
          {data?.place?.paymentOptions?.codAllowed && (
            <Row marginSize="s" verticallyCentered>
              <IconWrapper>
                <PaymentCashIcon />
              </IconWrapper>

              <Trans id="detail.codAllowed">Hotovost</Trans>
            </Row>
          )}

          {data?.place?.paymentOptions?.cardPaymentAllowed && (
            <Row marginSize="s" verticallyCentered>
              <IconWrapper>
                <PaymentCardIcon />
              </IconWrapper>

              <Trans id="detail.cardPaymentAllowed">Platba kartou</Trans>
            </Row>
          )}

          {data?.place?.paymentOptions?.prepaidAllowed && (
            <>
              <Row marginSize="s" verticallyCentered noBottomMargin>
                <IconWrapper>
                  <PaymentMobileIcon />
                </IconWrapper>

                <Trans id="detail.prepaidAllowed">Kartou online</Trans>
              </Row>
              <Row marginSize="zero" verticallyCentered>
                <DescriptionWrapper>
                  <Typography type={TypographyType.BodyMicroLight}>
                    <Trans id="detail.prepaidAllowed.description">
                      Před vyzvednutím zásilky v boxu zaplatíte dobírkovou
                      částku kartou.{' '}
                    </Trans>
                  </Typography>
                </DescriptionWrapper>
              </Row>
            </>
          )}
        </Typography>
      </Row>

      <Hr />

      <Row marginSize="s">
        <Typography type={TypographyType.BodyLight}>
          <Trans id="detail.openingHours">Otevírací doba</Trans>
        </Typography>
      </Row>

      <OpeningHours data={data?.place?.openingHours} />

      <GoogleMap
        position={{
          lat: data?.place?.location?.coordinates?.latitude,
          lng: data?.place?.location?.coordinates?.longitude,
        }}
        desktopHeight={500}
      />

      <CenteredRow marginSize="m" noBottomMargin>
        <Button
          buttonType={ButtonType.SECONDARY}
          className="gaButton gaButtonAction_navigate"
          // TODO: move links and similar things to config (after config cleanup)
          onClick={() => {
            addDataLayerItem({ event: 'place_detail_navigation' });
            window.open(
              `https://www.google.com/maps/place/${data?.place?.location?.coordinates?.latitude},${data?.place?.location?.coordinates?.longitude}`,
              '_blank',
            );
          }}
        >
          <Trans id="common.button.navigate">Navigovat</Trans>
        </Button>
      </CenteredRow>

      <HideOnDesktop>
        <Row marginSize="m" />
      </HideOnDesktop>
    </>
  );
};

import { Typography, TypographyType } from '@hp/atomic';
import { colors, spacing, typography } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import styled from 'styled-components';

type PriceBoxProps = {
  currency: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.gray};
  min-height: 114px;
  padding: 10px 0 20px;
  border-radius: 5px;
  margin: ${spacing.m} 0;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Price = styled.div`
  font-size: 40px;
  line-height: 38px;
  font-weight: ${typography.fontWeight.normal};
  margin-right: ${spacing.s};
`;

export const PriceBox: FC<PriceBoxProps> = ({ currency, children }) => {
  return (
    <Container>
      <Typography type={TypographyType.BodySmallLight} textAlign="center">
        <Trans id="pay.pricebox.text">Před vyzvednutím prosím zaplaťte</Trans>:
      </Typography>

      <PriceRow>
        <Price>{children}</Price>{' '}
        <Typography type={TypographyType.BodyRegular}>{currency}</Typography>
      </PriceRow>
    </Container>
  );
};

import { colors, spacing } from '@hp/theme';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

type InfoBoxProps = {
  icon: ReactNode;
  boxType?: 'success' | 'error';
};

type ContainerProps = Pick<InfoBoxProps, 'boxType'>;

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${({ boxType }) =>
    boxType === 'success'
      ? colors.green_bg_light
      : boxType === 'error'
      ? colors.red_opacity_005
      : colors.gray};
  padding: 12px 10px;
  border-radius: 5px;
  margin: ${spacing.m} 0;
`;

const IconWrapper = styled.div`
  margin-right: ${spacing.s};
`;

export const InfoBox: FC<InfoBoxProps> = ({ icon, boxType, children }) => {
  return (
    <Container boxType={boxType}>
      <IconWrapper>{icon}</IconWrapper>

      {children}
    </Container>
  );
};
